<template>
    <div class="intro-y box p-5 mt-5">
        <form method="post" class="grid grid-cols-12 gap-6" :class="{ 'disabled-form': loading }">
            <div v-if="loading" class="absolute z-100 left-0 top-0 w-full h-full flex items-center justify-center">
                <Preloader />
            </div>

            <div class="relative col-span-6">
                <label for="form-preview-title" class="form-label">
                    Название в превью
                    <sup v-if="v$.form.preview_title.required" class="text-theme-6">*</sup>
                </label>
                <label
                    v-if="v$.form.preview_title.$errors.length"
                    for="form-preview-title"
                    class="form-input-text-error"
                >
                    {{ getOverrideMessage(v$.form.preview_title.$errors[0].$message) }}
                </label>
                <input
                    id="form-preview_title"
                    v-model="form.preview_title"
                    type="text"
                    class="form-control w-full"
                    :class="{ 'form-input-error': v$.form.preview_title.$errors.length }"
                    placeholder=""
                    autocomplete="off"
                />
            </div>

            <div class="col-span-6">
                <label for="form-title" class="form-label">Название на детальной странице</label>
                <input
                    id="form-title"
                    v-model="form.title"
                    type="text"
                    class="form-control w-full"
                    placeholder="Введите текст или будет взято название из превью"
                    autocomplete="off"
                />
            </div>

            <div class="relative col-span-6">
                <label for="form-link" class="form-label">
                    Ссылка
                    <sup v-if="v$.form.slug.required" class="text-theme-6">*</sup>
                </label>
                <label v-if="v$.form.slug.$errors.length" for="form-link" class="form-input-text-error">
                    {{ getOverrideMessage(v$.form.slug.$errors[0].$message) }}
                </label>
                <input
                    id="form-link"
                    v-model="form.slug"
                    type="text"
                    class="form-control w-full"
                    :class="{ 'form-input-error': v$.form.slug.$errors.length }"
                    placeholder=""
                    autocomplete="off"
                />
            </div>

            <div class="relative col-span-6" title="Порядковый номер направления от 0 до 65535">
                <label for="form-order" class="form-label">
                    Сортировка
                    <sup v-if="v$.form.order.required" class="text-theme-6">*</sup>
                </label>
                <label v-if="v$.form.order.$errors.length" for="form-order" class="form-input-text-error">
                    {{ getOverrideMessage(v$.form.order.$errors[0].$message) }}
                </label>
                <input
                    id="form-order"
                    v-model="form.order"
                    type="text"
                    class="form-control w-full"
                    placeholder="Порядковый номер направления от 0 до 65535"
                    :class="{ 'form-input-error': v$.form.order.$errors.length }"
                    autocomplete="off"
                />
            </div>

            <div class="col-span-4">
                <label for="form-published" class="form-label">Опубликовано</label>
                <select id="form-published" v-model="form.published" class="form-select">
                    <option :value="true">Опубликовано</option>
                    <option :value="false">Не опубликовано</option>
                </select>
            </div>

            <div class="col-span-4">
                <label for="form-parent_id" class="form-label">Родительское направление</label>
                <Multiselect
                    id="form-parent_id"
                    v-model="form.parent_id"
                    track-by="preview_title"
                    label="preview_title"
                    value-prop="id"
                    placeholder="Выберите родителя"
                    :hide-selected="false"
                    :close-on-select="false"
                    :searchable="true"
                    :options="parentOptions"
                >
                    <template #option="{ option }">
                        {{ option.preview_title || option.title }}
                    </template>
                </Multiselect>
            </div>

            <div class="col-span-4">
                <label for="form-sale_visible" class="form-label">Текст акции на картинке</label>
                <select id="form-sale_visible" v-model="form.sale_visible" class="form-select">
                    <option :value="true">Виден</option>
                    <option :value="false">Скрыт</option>
                </select>
            </div>

            <div class="col-span-12">
                <label for="form-description" class="form-label">Описание</label>
                <CustomWyswig id="form-description" v-model="form.description" class="form-control w-full" />
            </div>

            <div class="col-span-12">
                <label class="form-label">Изображение на детальной странице</label>
                <UploadImageNew v-model="form.detail_image" recommended-sizes="1170х300px"></UploadImageNew>
            </div>

            <div class="relative col-span-6">
                <label class="form-label">
                    Изображение в квадратной плитке
                    <sup v-if="v$.form.preview_image_square.required" class="text-theme-6">*</sup>
                </label>
                <label v-if="v$.form.preview_image_square.$errors.length" class="form-image-text-error">
                    {{ getOverrideMessage(v$.form.preview_image_square.$errors[0].$message) }}
                </label>
                <UploadImageNew
                    v-model="form.preview_image_square"
                    recommended-sizes="270х310px"
                    :has-error="!!v$.form.preview_image_square.$errors.length"
                ></UploadImageNew>
            </div>

            <div class="relative col-span-6">
                <label class="form-label">
                    Изображение в горизонтально-прямоугольной плитке
                    <sup v-if="v$.form.preview_image_hr_rectangle.required" class="text-theme-6">*</sup>
                </label>
                <label v-if="v$.form.preview_image_hr_rectangle.$errors.length" class="form-image-text-error">
                    {{ getOverrideMessage(v$.form.preview_image_hr_rectangle.$errors[0].$message) }}
                </label>
                <UploadImageNew
                    v-model="form.preview_image_hr_rectangle"
                    recommended-sizes="570х310px"
                    :has-error="!!v$.form.preview_image_hr_rectangle.$errors.length"
                ></UploadImageNew>
            </div>

            <div class="relative col-span-6">
                <label class="form-label">
                    Изображение в вертикально-прямоугольной плитке
                    <sup v-if="v$.form.preview_image_vr_rectangle.required" class="text-theme-6">*</sup>
                </label>
                <label v-if="v$.form.preview_image_vr_rectangle.$errors.length" class="form-image-text-error">
                    {{ getOverrideMessage(v$.form.preview_image_vr_rectangle.$errors[0].$message) }}
                </label>
                <UploadImageNew
                    v-model="form.preview_image_vr_rectangle"
                    recommended-sizes="270х650px"
                    :has-error="!!v$.form.preview_image_vr_rectangle.$errors.length"
                ></UploadImageNew>
            </div>

            <div class="col-span-12">
                <label for="form-title" class="form-label">Заголовок достопримечательностей</label>
                <input
                    id="form-title"
                    v-model="form.attractions_title"
                    type="text"
                    class="form-control w-full"
                    placeholder=""
                    autocomplete="off"
                />
            </div>

            <div class="col-span-12">
                <label class="form-label font-bold">Достопримечательности</label>
                <p v-if="attractionOptions.length === 0">Отсутствуют</p>
                <MultiselectTable
                    v-else
                    v-model="form.attractions"
                    :options="attractionOptions"
                    :header-labels="headerLabels"
                />
            </div>

            <div class="col-span-12">
                <label for="form-title" class="form-label">Заголовок поиска</label>
                <input
                    id="form-title"
                    v-model="form.search_title"
                    type="text"
                    class="form-control w-full"
                    placeholder=""
                    autocomplete="off"
                />
            </div>

            <div class="col-span-12">
                <label class="form-label font-bold">Кастомные поля</label>
                <TeamsTable v-if="form.seo" v-model="form.seo" :meta="null" :headers="seoHeaders" class="mb-3" />
                <SearchParamFillInfo />
            </div>

            <div class="col-span-12 flex items-center justify-end mt-5">
                <router-link :to="{ name: 'popular-directions' }" class="btn btn-secondary w-24 mr-2 mb-2"
                    >Отмена</router-link
                >
                <button type="submit" class="btn btn-primary mb-2 mr-2" @click.prevent="save(false)">
                    Сохранить и выйти
                </button>
                <button type="submit" class="btn btn-primary mb-2" @click.prevent="save(true)">Сохранить</button>
            </div>
        </form>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required, numeric, minValue, maxValue } from '@vuelidate/validators';
import Preloader from '@/components/preloader/Main';
import UploadImageNew from '@/components/upload-image/UploadImageNew';
import CustomWyswig from '@/components/base/custom-wyswig/Main';
import TeamsTable from '@/components/tables/TeamsTable';
import { popularDirectionsMixin } from '@/mixins/form/popular-directions-mixin';
import { errorResponse, formPreparation } from '@/mixins/form/form-mixin';
import Multiselect from '@vueform/multiselect';
import SearchParamFillInfo from '@/components/banners/SearchParamFillInfo';
import MultiselectTable from '@/components/tables/MultiselectTable';
import { helper as $h } from '@/utils/helper';

export default {
    name: 'Create',
    components: {
        MultiselectTable,
        SearchParamFillInfo,
        Preloader,
        UploadImageNew,
        CustomWyswig,
        TeamsTable,
        Multiselect,
    },
    mixins: [popularDirectionsMixin, errorResponse, formPreparation],
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            headerLabels: ['Достопримечательность'],
        };
    },
    validations() {
        return {
            form: {
                preview_title: { required },
                slug: { required },
                order: { required, numeric, minValue: minValue(0), maxValue: maxValue(65535) },
                preview_image_square: { required },
                preview_image_hr_rectangle: { required },
                preview_image_vr_rectangle: { required },
            },
        };
    },
    created() {
        this.$store.commit('main/setPageHeader', 'Создать направление');
        this.fetchCreationParams();
    },
    methods: {
        async save(flag) {
            const isFormCorrect = await this.v$.$validate();
            if (!isFormCorrect) {
                this.$notify({ text: 'Заполните все поля правильно', type: 'error' });
                return;
            }

            this.loading = true;

            try {
                const formData = this.formPreparation({ nullsAsUndefineds: false, imagesAsObject: true });
                const { data } = await this.axios.post('/popular-directions', formData);
                this.$notify(data.message);
                if (flag) {
                    await this.$router.push({ name: 'popular-direction-edit', params: { id: data.data.id } });
                } else {
                    await this.$router.push({ name: 'popular-directions' });
                }
            } catch (ex) {
                this.errorResponse(ex);
            } finally {
                this.loading = false;
            }
        },

        async fetchCreationParams() {
            this.loading = true;

            try {
                const { data } = await this.axios.get(`/popular-directions/create`);

                this.parentOptions = data.data.directions;
                this.attractionOptions = data.data.attractions;
            } catch (ex) {
                console.log('cannot fetch popular-directions creation params ' + ex);
                // this.$router.push('/error-page');
            } finally {
                this.loading = false;
            }
        },
        getOverrideMessage(message) {
            return $h.getOverrideMessage(message);
        },
    },
};
</script>

<style scoped></style>
